import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Display from './pages/display';
import EndPoint from './pages/Endpoint';


function App() {
  return (
    <div>
      <Display/>
      {/* <EndPoint/> */}
    </div>
  );
}

export default App;
