import React, { Component } from 'react';
import {MDBDataTable} from 'mdbreact';
import axios from 'axios';

import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import '../pages/Endpoint.css';

class Display extends Component{
    constructor(){
        super();
        this.state={
            data: '',
            messagelist:[],
        }
        //to call something every 5 seconds
        setInterval(()=>this.fetchUser(),15000);
    }

    componentDidMount(){
        this.fetchUser();
    }

    // getFlowData(){
    //     this.setState({
    //         messagelist:[]
    //     })
    //     // alert("getmessage called");
    //     let url = "http://localhost:8080/testflow_db/dispflow2.php";
    //     axios.get(url).then(response=>{
    //         this.setState({
    //             messagelist:response.data                
    //         })
    //         // console.log(response.data);
    //     })
    // }

    fetchUser = () => {
        // const url = "companylist.json";
        // let url = "http://localhost:8080/testflow_db/testapi.php";
        let url = "https://testflow.aarohiweblogger.com/flow2/testapi.php";
        axios.get(url)
        .then(res=>{
            this.setState({
                data: {
                        columns:[
                                    // {
                                    //     label: 'SrNo',
                                    //     field: 'SrNo',
                                    //     sort: 'asc'
                                    // },                                    
                                    {
                                        label: 'Uid',
                                        field: 'hardware_id',
                                        sort: 'asc'
                                    },
                                    {
                                        label: 'TotalFlow',
                                        field: 'total_flow',
                                        sort: 'asc'
                                    },
                                    {
                                        label: 'FlowRate',
                                        field: 'flow_rate',
                                        sort: 'asc'
                                    },
                                    {
                                        label : 'Alarm',
                                        field: 'site_alarm',
                                        sort : 'asc'
                                    },
                                    {
                                        label: 'Battery(%)',
                                        field: 'battery_level',
                                        sort: 'asc'
                                    },
                                    {
                                        label : 'Time',
                                        field: 'time',
                                        sort : 'asc'
                                    },
                                    {
                                        label: 'Date',
                                        field: 'date',
                                        sort: 'asc'
                                    }
                                ],
                        rows:res.data
                    }
                })
                console.log(res.data);
        })
        .catch(err=> console.log(err));
    }

    render(){
        return(                 
                <div className="container">
                    <div className="row p-5">
                        <div className="col-md-12 col-lg-12 col-sm-12 p-3 border">
                            <br/>
                            <h2 className="text-center">Flow Monitoring App</h2>
                            <MDBDataTable 
                                responsive
                                // striped 
                                bordered 
                                // borderless
                                small
                                hover 
                                data={this.state.data}
                            />
                            {/* <table className="table table-bordered table-sm">
                                <thead className="text-danger">
                                    <tr>
                                        <th> Hardware Uid </th>
                                        <th> Total Flow </th>
                                        <th> Flow Rate </th>
                                        <th> Alarm </th>
                                        <th> Battery(%) </th>
                                        <th> Time </th>
                                        <th> Date </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.messagelist.map((row , index)=>{
                                            return <tr key={index}>
                                                        <td>{row.hardware_id}</td>
                                                        <td>{row.total_flow}</td>
                                                        <td>{row.flow_rate}</td>
                                                        <td>{row.site_alarm}</td>
                                                        <td>{row.battery_level}</td>
                                                        <td>{row.time}</td>
                                                        <td>{row.date}</td>
                                                </tr>
                                        })
                                    }
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                    {
                        loadProgressBar()
                    }
                </div>
        );
    }
}

export default Display;
